import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Flex from '../components/Flex'
import Image from '../components/Image'
import Container from '../components/Container'

const propTypes = {
  data: PropTypes.object,
}

const Contact = ({ data }) => (
  <Layout>
    <SEO title="Kontakt" />

    <Container smallplus>
      <p sx={{ mb: 2 }}>Kontakt</p>
      <h1 sx={{ hyphens: `manual` }}>Unsere Kontaktdaten für Sie</h1>
    </Container>

    <Flex
      large
      sx={{
        flexDirection: `column`,
        p: 0,
        height: [`240px`, `320px`, `400px`, `400px`],
      }}
    >
      <Image
        fluid={data.header.childImageSharp.gatsbyImageData}
        sx={{
          bg: `gray.5`,
        }}
      />
    </Flex>

    {/** 
    <Hero large fluid={data.header.childImageSharp.fluid} padding={0} height={`50vh`} />
    */}

    <Container>
      <h2 sx={{ mb: 0 }}>RAin Friederike E.L. Vilmar</h2>
      <p>Fachanwältin für Strafrecht</p>
      <p>
        Mail: {` `}
        <Styled.a href="mailto:info@ra-vilmar.de" rel="noreferrer noopener">
          info@ra-vilmar.de
        </Styled.a>
      </p>
      <p>
        Tel: {` `}
        <Styled.a href="tel:+4969240031700" rel="noreferrer noopener">
          +49 69 240 031 70-0
        </Styled.a>
        <br />
        Fax: +49 69 240 031 70-9
      </p>
      <address>
        Untermainkai 19
        <br />
        60329 Frankfurt am Main
        <br />
        <Styled.a
          href="https://goo.gl/maps/8nFeWYSswL4GDbNB6"
          target="_blank"
          rel="noreferrer noopener"
        >
          Anfahrt
        </Styled.a>
      </address>
      <p>
        <Styled.a href="/Vollmacht_Mai_2023.pdf" rel="noreferrer noopener">
          Vollmacht (Mai 2023) herunterladen
        </Styled.a>
      </p>
    </Container>
    <Container>
      <div>
        <p>
          Generelle Anfragen
          <br />
          - Sekretariat -<br />
          <Styled.a href="mailto:sekretariat@ra-vilmar.de" rel="noreferrer noopener">
            sekretariat@ra-vilmar.de
          </Styled.a>
        </p>
      </div>
    </Container>

    <Flex sx={{ flexDirection: `column` }}>
      <h2>Kooperationen</h2>
      <p>
        In Kooperation mit
        <br />
        <br />
        JAN SMOLLICH & KOLLEGEN *
        <br />
        Rechtsanwälte | Fachanwälte | Mediatoren | Notar
        <br />
        Südergraben 47
        <br />
        24937 Flensburg
        <br />
        <Styled.a
          href="https://www.smollich-kollegen.de/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Website
        </Styled.a>
        <br />
      </p>
      <p sx={{ mt: 3 }}>
        und
        <br />
        <br />
        Martenstein Rechtsanwälte *
        <br />
        Untermainkai 19
        <br />
        60329 Frankfurt am Main
        <br />
        Tel. 069 / 2475189-0
        <br />
        Fax 069 / 24751789-9
        <br />
        <Styled.a
          href="https://www.Franchiserecht-Frankfurt.de"
          target="_blank"
          rel="noreferrer noopener"
        >
          Website
        </Styled.a>
      </p>
      <p sx={{ mt: 3 }}>* beide als selbst&auml;ndige Rechtstr&auml;ger</p>
    </Flex>
  </Layout>
)

Contact.propTypes = propTypes

export default Contact

export const query = graphql`
  {
    header: file(relativePath: { eq: "rav/igor-flek-q18A6PEaQ6U-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
    anita: file(relativePath: { eq: "rav/anita.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
    nadine: file(relativePath: { eq: "rav/nadine.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
    karolina: file(relativePath: { eq: "rav/karolina.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
  }
`
